import { createRouter, createWebHistory } from 'vue-router'
import SiteIndex from '../views/SiteIndex.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: SiteIndex
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
